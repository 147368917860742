/* Work component styles */
.work {
  /* min-width: 400px; */
  margin: 4rem auto;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 80%;
}

.work h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--accent-color);
}

/* WorkCard styles */
.work-card {
  padding: 1rem;
  margin: 1rem;
  background-color: var(--card-background-color) !important;
  transition: transform 0.3s ease;
}

.work-card:hover {
  transform: translateY(-5px);
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  height: 100%;
}

.work-card-project {
  flex-grow: 1;
  height: 8rem;
  overflow-y: auto;
}

.work-card-project-title {
  font-size: 1.5rem !important;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--accent-color) !important;
}
.work-card-project-description {
  color: var(--card-text-color) !important;
}

.work-card-technology {
  height: 5rem;
  overflow-y: auto;
}

.work-card-technologies-title {
  font-size: 1.2rem !important;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: var(--accent-color) !important;
}

.work-card-technologies-items {
  color: var(--card-text-color) !important;
}

.work-card-button-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

/* Carousel customization */
.react-multi-carousel-item {
  display: flex;
  justify-content: center;
}

.react-multi-carousel-list {
  padding: 2rem 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .work {
    max-width: 95%;
    padding: 1rem;
  }

  .work h1 {
    font-size: 2rem;
  }

  .work-card {
    padding: 0.5rem;
  }
}

/* .work {
  max-width: 80%;
  margin: 3rem auto;
  padding: 4rem;
}

.work-card {
  padding: 2rem;
  max-width: 800px;
  margin: 2rem auto;
  flex: 1;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  height: 100%;
}
.work-card-project-description {
  flex-grow: 1;
  height: 10rem;
  overflow-y: auto;
}
.work-card-technology {
  height: 5.5rem;
}
.work-card-button-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.work-card-button-section-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
} */
