.contact-form {
  margin: 2rem auto;
  padding: 2rem auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 80%;
}
.contact-form h1 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: var(--accent-color);
}
.contact-form-card {
  padding: 1rem;
  margin: 1rem;
  background-color: var(--card-background-color) !important;
  transition: transform 0.3s ease;
}

.card-actions {
  display: flex;
  justify-content: center;
  gap: 8rem !important;
  margin: 1rem;
}

.contact-form-name {
  color: var(--accent-color) !important;
}
.contact-form-description {
  color: var(--card-text-color) !important;
}

@media (max-width: 768px) {
  .contact-form {
    max-width: 95%;
    padding: 1rem;
    min-width: 100px;
  }

  .contact-form h1 {
    font-size: 2rem;
  }

  .contact-form-card {
    padding: 0.5rem;
  }
}
