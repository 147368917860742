.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
}
.logo > img {
  border-radius: 50%;
  height: 4rem;
}
.theme-toggle-button {
  margin-left: auto;
  margin-right: 1.5rem;
  & svg {
    color: var(--toggle-button-color);
  }

  @media (max-width: 720) {
    margin-right: 1rem;
  }
}
.theme-toggle-button-dark {
  --toggle-button-color: #f0f0f0;
}
.theme-toggle-button-light {
  --toggle-button-color: #121212;
}

.NavLinks {
  & a {
    color: var(--text-color);
    font-size: 1.5rem;
    font-weight: 500;
    text-decoration: none;
  }
}
.navlinks-menu {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.mobile-btn {
  display: none;
}
@media screen and (max-width: 720px) {
  .theme-toggle-button {
    margin-right: 1.5rem;
  }

  .nav {
    /* flex-direction: column; */
    gap: 1rem;
  }
  .mobile-btn {
    display: block;
    position: fixed;
    top: 2.5rem;
    right: 1rem;
    cursor: pointer;
    z-index: 10;
    color: var(--text-color);
  }
  .navlinks-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: -100%;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    gap: 5rem;

    background-color: var(--background-color);
    color: var(--text-color);
    z-index: 10;
    transition: left 1s;
  }

  .active {
    left: 0;
  }

  .NavLinks a {
    font-size: 2rem;
  }
}

@import url(https://cdn.jsdelivr.net/npm/theme-toggles@4.10.1/css/expand.min.css);
button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
svg {
  scale: 250%;
}
